.slide-contatti {
  .tab-selector {
    display: none;

    @include only-smartphone {
      display: block;
    }

    @include only-tablet {
      display: block;
      margin: 4vh 0;
    }

    span {
      border-bottom: 0.063em solid $color-tab-selector-link;
      color: $color-tab-selector-link;
      cursor: pointer;
      display: inline-block;
      font-family: "PT Sans";
      padding-bottom: 0.375em;
      transition: all 0.5s;
      width: 6em;

      &:hover,
      &.is-active {
        border-color: $color-highlight;
        color: $color-highlight;
        text-decoration: none;
      }
    }
  }

  .content .description {
    @include only-smartphone {
      padding: 0;
    }
  }

  .description {
    @include only-smartphone {
      left: calc(-100vw + 6vh);
      padding: 2vh 0 0;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh);

      h3 {
        display: none;
      }

      .fa {
        margin: 0.3em;
      }

      &.is-selected {
        left: 6vh;
      }
    }

    @include only-tablet {
      left: calc(-100vw + 6vh);
      padding: 2vh 0 0;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh);

      .fa {
        margin-right: 0.3em;
      }

      &.is-selected {
        left: 6vh;
      }
    }
  }

  form {
    float: right;
    margin-right: 20vh;
    min-height: 40vh;
    padding-top: 10vh;
    position: relative;
    width: 40vw;

    @include only-smartphone {
      float: none;
      left: calc(100vw + 6vh);
      padding-top: 2vh;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh);

      &.is-selected {
        left: 6vh;
      }
    }

    @include only-tablet {
      float: none;
      left: calc(100vw + 6vh);
      padding-top: 2vh;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh);

      &.is-selected {
        left: 6vh;
      }
    }

    .loader-container {
      background: $color-overlay;
      display: none;
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: opacity 0.5 s;
      width: 100%;
      z-index: 10;

      img {
        height: 200px;
        position: relative;
        top: 50%;
        transform: translateY(-80%);
        width: 200px;
      }
    }

    .message-container {
      color: $color-text;
      font: $typography-form;
      z-index: -999;

      @include only-smartphone {
        font: $typography-form-smartphone;
      }

      @include only-tablet {
        font: $typography-form-smartphone;
      }

      &::before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        margin-right: 0.5em;
        text-rendering: auto;
      }

      &.message-success::before {
        color: #4c4;
        content: "\f00c";
      }

      &.message-success > span {
        color: #4c4;
      }

      &.message-fail::before {
        color: #f00;
        content: "\f12a";
      }

      &.message-fail > span {
        color: #f00;
      }

      > a {
        color: $color-highlight;
        display: none;
        font-size: 1em;
        padding-bottom: 5px;
        position: relative;
        text-decoration: none;
        transition: all 0.2s;

        @include only-smartphone {
          font-size: 0.8em;
        }

        &::after {
          border-bottom: 1px solid $color-highlight;
          bottom: 0;
          content: " ";
          display: inline-block;
          left: 0;
          position: absolute;
          transition: all 0.2s;
          width: 0;
        }

        &:hover {
          color: $color-highlight;

          &::after {
            width: 100%;
          }
        }
      }
    }

    &.is-pending {
      .form-container {
        filter: opacity(50%);
        transition: all 0.5;
        z-index: 0;
      }

      .loader-container {
        display: block;
        opacity: 1;
      }
    }

    &.is-submitted {
      .form-container {
        display: none;
        transition: all 0.5;
      }

      .loader-container {
        opacity: 0;
        z-index: -9999;
      }

      .message-container {
        position: relative;
        z-index: 999;
      }
    }

    input[type="text"],
    input[type="email"],
    textarea {
      @include placeholder-color($color-form-element-text);
      background-color: $color-form-element-background;
      border: 0.125em solid $color-form-element-border;
      border-radius: 5px;
      color: $color-text;
      display: block;
      font: $typography-form;
      padding: 0.625em 25px;
      transition: border 0.5s;

      @include only-smartphone {
        font: $typography-form-smartphone;
        padding: 0.3em 0.5em;
      }

      @include only-tablet {
        font: $typography-form-tablet;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $color-text;
      }

      &:invalid {
        box-shadow: none;
      }
    }

    input[type="text"],
    input[type="email"] {
      float: left;
      margin: 0 1% 0.938em;
      width: 48%;

      @include only-smartphone {
        margin-bottom: 2%;
      }
    }

    textarea {
      margin: 0 1% 0.938em;
      width: 98%;

      @include only-smartphone {
        margin-bottom: 2%;
      }
    }

    .checkbox-container {
      color: $color-description;
      font: $typography-form;
      margin: 0 1%;
      text-align: left;

      @include only-smartphone {
        font: $typography-form-smartphone;
        text-align: center;
      }

      @include only-tablet {
        font: $typography-form-tablet;
        text-align: center;
      }
    }

    .checkbox {
      &:not(:checked),
      &:checked {
        left: -9999px;
        position: absolute;

        + label {
          cursor: pointer;
          padding-left: 25px;
          position: relative;
          transition: color 0.5s;

          &:hover {
            color: $color-text;
          }

          &::before {
            background: $color-form-element-background;
            border: 1px solid $color-form-element-border;
            border-radius: 3px;
            content: "";
            height: 17px;
            left: 0;
            position: absolute;
            top: 0.25em;
            width: 1em;
          }

          &::after {
            color: $color-text;
            content: "✔";
            font-size: 18px;
            left: 4px;
            line-height: 0.8;
            position: absolute;
            top: 3px;
            transition: all 0.2s;
          }
        }
      }

      &:not(:checked) + label::after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked + label::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    button {
      background-color: transparent;
      border: 1px solid $color-link;
      color: $color-link;
      cursor: pointer;
      display: block;
      float: right;
      font: $typography-links;
      margin-right: 1%;
      overflow: hidden;
      padding: 0.313em 3.125em;
      position: relative;
      transition: 0.5s;

      @include only-smartphone {
        margin-right: 0;
        margin-top: 3vh;
        padding: 0.313em 0;
        width: 100%;
      }

      @include only-tablet {
        margin-right: 0;
        margin-top: 7vh;
        padding: 0.313em 0;
        width: 100%;
      }

      &:hover {
        color: invert($color-link);

        &::after {
          transform: skewX(30deg);
          width: 120%;
        }
      }

      &::after {
        background: $color-link;
        bottom: -1px;
        content: " ";
        height: 120%;
        left: -10%;
        position: absolute;
        transform: skewX(15deg);
        transition: 0.5s;
        width: 0;
        z-index: -1;
      }
    }
  }
}

.map {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);

  @include  only-smartphone {
    height: 100%;
  }

  iframe {
    height: 55vh;
    width: 100%;

    @include only-smartphone {
      height: 100%;
      width: 100%;
    }
  }
}

.legal-info {
  bottom: 10vh;
  color: $color-highlight;
  position: absolute;
  text-align: center;
  width: 100%;

  @include only-tablet {
    bottom: 5vh;
  }

  @include only-smartphone {
    bottom: 5vh;
  }

  a {
    color: $color-form-element-text;
    font-size: 1em;
    padding-bottom: 5px;
    position: relative;
    text-decoration: none;
    transition: all 0.2s;

    @include only-smartphone {
      font-size: 0.8em;
    }

    &::after {
      border-bottom: 1px solid $color-highlight;
      bottom: 0;
      content: " ";
      display: inline-block;
      left: 0;
      position: absolute;
      transition: all 0.2s;
      width: 0;
    }

    &:hover {
      color: $color-highlight;

      &::after {
        width: 100%;
      }
    }
  }
}
