* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

button {
  &:active,
  &:focus {
    outline: none;
  }
}

body {
  background: $color-background;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.clear {
  clear: both;
  display: block;
}

.swiper-slide .content {
  height: 100%;
  // padding: 11vh;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: $index-content;

  /*
  @include only-smartphone {
    padding: 4vh;
  }

  @include only-tablet {
    padding: 4vh;
  }
  */

  .logo {
    margin-top: 10vh;

    @include only-smartphone {
      margin-top: 6vh;
    }

    @include only-tablet {
      margin-top: 10vh;
    }

    img {
      height: 160px;
      width: 160px;

      @include only-smartphone {
        height: 95px;
        width: 95px;
      }

      @include only-tablet {
        height: 160px;
        width: 160px;
      }

      @include only-notebook {
        height: 120px;
        width: 120px;
      }
    }
  }

  .description {
    padding: 5vh 10vh 0;
    text-align: left;

    @include only-smartphone {
      padding-top: 2vh;
    }

    @include only-tablet {
      padding-top: 2vh;
    }

    @include only-notebook {
      padding-top: 0;
    }

    h3 {
      color: $color-headings;
      font: $typography-headings;

      @include only-smartphone {
        font: $typography-headings-smartphone;
        text-align: center;
      }

      @include only-tablet {
        font: $typography-headings-tablet;
        text-align: center;
      }
    }

    p {
      color: $color-description;
      font: $typography-description;
      line-height: 1.5em;
      padding-top: 3vh;

      @include only-smartphone {
        font: $typography-description-smartphone;
        line-height: 1.3em;
        margin: 0;
        padding-top: 2vh;
      }

      @include only-tablet {
        font: $typography-description-tablet;
        line-height: 1.3em;
        margin: 0;
        padding-top: 5vh;
      }

      @include only-notebook {
        padding-top: 0;
      }
    }

    a {
      color: $color-link;
    }

    .details {
      border: 0.063em solid $color-link;
      box-sizing: border-box;
      color: $color-link;
      display: block;
      float: left;
      font: $typography-links;
      margin-top: 5vh;
      overflow: hidden;
      padding: 0.313em 3.125em;
      position: relative;
      transition: 0.5s;

      @include only-smartphone {
        padding: 0.313em 0;
        text-align: center;
        width: 100%;
      }

      @include only-tablet {
        margin-top: 10vh;
        padding: 0.313em 0;
        text-align: center;
        width: 100%;
      }

      @include only-notebook {
        padding-top: 0;
      }

      span {
        position: relative;
        z-index: $index-link-foreground;
      }

      &:hover {
        color: invert($color-link);

        &::before {
          width: 120%;
        }
      }

      &::before {
        background: $color-link;
        bottom: -0.063em;
        content: " ";
        height: 120%;
        left: -10%;
        position: absolute;
        transform: skewX(30deg);
        transition: 0.5s;
        width: 0;
      }
    }
  }
}

.next-slide {
  bottom: 10vh;
  position: absolute;
  text-align: center;
  width: 100%;

  @include only-smartphone {
    display: none;
  }

  @include only-tablet {
    display: none;
  }

  svg {
    height: 3.125em;
    transform: scale(1);
    transition: all 0.2s;
    width: 3.125em;

    line,
    circle {
      stroke: $color-highlight;
      stroke-width: 2px;
    }

    circle {
      background-color: transparent;
      fill: none;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

h3 {
  color: $color-headings;
  font-family: $typography-headings;
  font-size: 3.75em;
}
