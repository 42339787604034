.menu {
  position: fixed;
  right: 0;
  top: 0;
  z-index: $index-menu;

  .content {
    background-color: $color-theme;
    height: 100vh;
    position: absolute;
    right: -25em;
    transition: all 0.2s;
    width: 25em;
    z-index: $index-menu;

    @include only-smartphone {
      right: -100vw;
      transition: all 0.5s;
      width: 100vw;
    }

    @include only-tablet {
      right: -100vw;
      transition: all 0.5s;
      width: 100vw;
    }
  }

  .mCSB_scrollTools {
    left: 0;
    right: auto;

    @include only-smartphone {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    @include only-tablet {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    .mCSB_draggerContainer {
      padding-left: 0.625em;
    }

    .mCSB_dragger {
      background-color: $color-scrollbar-dragger;
      border-radius: 0.625em;
      padding: 0.188em 0;
    }

    .mCSB_dragger_bar {
      background-color: $color-theme;
      width: 0.625em;
    }
  }

  .mCSB_inside > .mCSB_container {
    margin-left: 2.188em;
    margin-right: 0;

    @include only-smartphone {
      margin-left: 0;
    }

    @include only-tablet {
      margin-left: 0;
    }
  }
}

.menu-open {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10vh;
  top: 10vh;
  width: 6.25em;

  @include only-smartphone {
    right: 6vh;
    top: 6vh;
  }

  @include only-tablet {
    right: 6vh;
    top: 6vh;
  }

  svg {
    height: 2.125em;
    width: 6.25em;

    line {
      stroke: $color-highlight;
      stroke-width: 0.2em;
      transition: all 0.3s;

      &.line1 {
        stroke-dasharray: 70 100;
      }

      &.line2 {
        stroke-dasharray: 50 100;
      }

      &.line3 {
        stroke-dasharray: 30 100;
      }
    }
  }

  &:hover svg line {
    &.line1 {
      stroke-dasharray: 30 100;
    }

    &.line3 {
      stroke-dasharray: 70 100;
    }
  }
}

.menu-overlay {
  cursor: e-resize;
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 1s;
  width: 100vw;
  z-index: $index-menu-overlay;
}

.options {
  display: block;
  height: 80vh;
  margin: 0;
  overflow: hidden;
  padding: 0;

  @include only-smartphone {
    height: 84vh;
  }

  @include only-tablet {
    height: 84vh;
  }

  li {
    display: block;
    height: 12vh;
    margin-top: 0.625em;
    overflow: hidden;

    @include only-smartphone {
      margin-top: 0;
    }

    @include only-tablet {
      margin-top: 0;
    }
  }

  .option {
    background-position: center;
    background-size: 100% auto;

    @each $slide in $slides {
      &.option-#{$slide} {
        background-image: url("../../app/assets/images/menu/menu-#{$slide}.jpg");
      }
    }
  }

  a {
    color: $color-highlight;
    display: block;
    font-family: "PT Sans";
    font-size: 1.25em;
    font-weight: bold;
    height: 12vh;
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &::before {
      background-color: $color-theme;
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      top: -12vh;
      transition: all 0.3s;
      width: 100%;
    }

    &:hover {
      &::before {
        top: 0;
      }
    }

    span {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// Language Selector box
.language-selector {
  height: 20vh;
  position: relative;

  @include only-smartphone {
    height: 16vh;
  }

  @include only-tablet {
    height: 16vh;
  }

  .menu-close {
    background-color: $color-theme;
    border-width: 0;
    cursor: pointer;
    height: 1.875em;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.875em;
    top: 50%;
    width: 1.875em;

    &:hover {
      transform: rotate(180deg);
      transition: all 0.5s;
    }

    svg {
      height: 1.875em;
      width: 1.875em;

      line {
        stroke: $color-highlight;
        stroke-width: 0.125em;
      }
    }
  }

  ul {
    display: block;
    left: 1.875em;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;

    li {
      display: inline-block;
    }
  }

  a {
    border-bottom: 0.063em solid $color-language-selector-link;
    color: $color-language-selector-link;
    display: inline-block;
    font-family: "PT Sans";
    padding-bottom: 0.375em;
    text-align: center;
    transition: all 0.5s;
    width: 1.875em;

    &:hover,
    &.is-active {
      border-color: $color-highlight;
      color: $color-highlight;
      text-decoration: none;
    }
  }
}

.is-menu-open {
  .menu .content {
    right: 0;
  }

  @include only-desktop {
    .wallpaper {
      transform: translateX(-12.5em);
    }
  }

  .menu .menu-overlay {
    display: block;
    opacity: 1;
  }
}
