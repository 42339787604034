.modals-overlay {
  background-color: $color-overlay;
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s;
  z-index: $index-hidden;

  &.is-visible {
    opacity: 1;
    z-index: $index-modal-overlay;
  }
}

.modals {
  .swiper-container-horizontal {
    height: 100%;
  }
}

.modal {
  background-color: $color-modal-background;
  border-radius: 0.938em;
  bottom: 10vh;
  display: block;
  height: 80vh;
  left: 10vh;
  opacity: 0;
  padding: 10vh;
  position: fixed;
  right: 10vh;
  top: 10vh;
  transform: scale(0.9);
  transition: all 0.5s;
  z-index: $index-hidden;

  @include only-smartphone {
    bottom: 0;
    height: 100vh;
    left: 0;
    padding: 10vh 5vh 5vh;
    right: 0;
    top: 0;
  }

  @include only-tablet {
    bottom: 0;
    height: 100vh;
    left: 0;
    padding: 10vh 5vh 5vh;
    right: 0;
    top: 0;
  }

  &.is-visible {
    opacity: 1;
    transform: scale(1);
    z-index: $index-modal;
  }

  .swiper-container {
    height: 100%;

    .swiper-slide {
      height: 100%;
      overflow-y: auto;
    }
  }

  .modal-close {
    position: absolute;
    right: 4.5vh;
    text-align: right;
    top: 4.5vh;

    img {
      width: 2vh;

      @include only-smartphone {
        width: 4vh;
      }

      @include only-tablet {
        width: 4vh;
      }
    }
  }

  .modal-swiper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .modal-slide {
    display: block;
    height: 100%;
    width: 100%;
  }

  .swiper-pagination {
    padding: 2vh 0;
    width: calc(100vw - 40vh);
  }

  .swiper-button-next {
    background-image: url("../../app/assets/images/arrow-right.svg");
    right: 3vh;

    @include only-smartphone {
      display: none;
    }

    @include only-tablet {
      display: none;
    }
  }

  .swiper-button-prev {
    background-image: url("../../app/assets/images/arrow-left.svg");
    left: 3vh;

    @include only-smartphone {
      display: none;
    }

    @include only-tablet {
      display: none;
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  .swiper-pagination-bullet {
    background-color: transparent;
    border: 0.13em solid $color-theme;
    border-radius: 50%;
    height: 1em;
    margin: 0 0.3em;
    opacity: 1;
    transition: all 0.5s;
    width: 1em;

    &:hover,
    &.swiper-pagination-bullet-active {
      background-color: $color-theme;
      border-color: $color-theme;
    }
  }

  .swiper-slide-youtube iframe {
    height: 90%;
    width: calc(100vw - 40vh);

    @include only-smartphone {
      width: 90%;
    }

    @include only-tablet {
      width: 90%;
    }
  }

  h2 {
    color: $color-theme;
    font: $typography-modal-headings;
  }

  h3 {
    color: $color-theme;
    font: $typography-modal-headings-minor;
  }

  p,
  th,
  td {
    font: $typography-modal-body;
  }

  .gallery {
    height: 100%;
    text-align: center;

    img {
      max-height: 90%;
      max-width: 90%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.slide-picture-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  &::after {
    clear: both;
    content: " ";
    display: table;
  }

  img {
    display: block;
    float: left;
    margin-left: 5%;
    max-height: 100%;
    max-width: 35%;

    @include only-tablet {
      display: inline;
      margin-left: 0;
      margin-right: 5%;
      width: 50%;
    }
  }

  p {
    float: right;
    font: $typography-modal-body;
    margin-right: 5%;
    max-width: 50em;
    width: 50%;

    @include only-tablet {
      float: none;
      margin-right: 0;
      width: 100%;
    }
  }
}

.slide-text {
  @include only-smartphone {
    font-size: 0.8em;
  }
}
