.main-swiper {
  &.swiper-container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .swiper-wrapper {
    overflow: hidden;
  }

  .swiper-slide {
    display: flex;
    font-size: 1.125em;
  }

  &.swiper-container-vertical {
    > .swiper-pagination-bullets {
      right: 10vh;

      .swiper-pagination-bullet {
        margin: 0.938em 0;
      }
    }
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 0;
    height: auto;
    opacity: 1;
    position: relative;
    transition: all 0.5s;
    width: auto;

    &:hover {
      background-color: $color-highlight;

      &:hover .swiper-pagination-bullet-label {
        opacity: 1;
        right: 3.125em;
      }
    }

    .swiper-pagination-bullet-label {
      color: $color-highlight;
      font-family: "PT Sans";
      opacity: 0;
      position: absolute;
      right: 1.875em;
      text-transform: uppercase;
      top: -0.2em;
      transition: all 0.5s;

      &::after {
        border-bottom: 0.063em solid $color-highlight;
        content: " ";
        display: block;
        position: absolute;
        right: -1.875em;
        top: 0.563em;
        width: 1.563em;
      }
    }

    .swiper-pagination-bullet-inner {
      background-color: transparent;
      border: 0.063em solid $color-highlight;
      border-radius: 50%;
      display: block;
      height: 0.938em;
      width: 0.938em;
    }
  }

  .swiper-pagination-bullet-active .swiper-pagination-bullet-inner {
    background-color: $color-highlight;
  }
}

.swiper-pagination {
  @include only-smartphone {
    display: none;
  }

  @include only-tablet {
    display: none;
  }
}
