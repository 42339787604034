.slide-home {
  h1 {
    img {
      padding-top: 20vh;
      transition: all 1.5s;
      width: 250px;
    }

    &.animate-logo {
      img {
        padding-top: 10vh;
        width: 160px;

        @include only-tablet {
          padding-top: 10vh;
        }
      }
    }
  }

  h2 {
    color: $color-headings;
    font: $typography-headings;
    padding: 10vh 5vw 0;

    @include only-smartphone {
      font: $typography-headings-smartphone;
    }

    @include only-tablet {
      font: $typography-headings-tablet;
    }
  }
}
