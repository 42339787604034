.loader {
  background-color: $color-theme;
  height: 100vh;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: $index-loader;

  svg {
    height: 200px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
  }
}
