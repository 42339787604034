.slide-linee {
  .particles {
    height: 50vh;
    position: absolute;
    right: 20vh;
    width: 40vw;

    @include only-smartphone {
      height: 88vh;
      right: 6vh;
      top: 6vh;
      width: calc(100vw - 12vh);
    }

    @include only-tablet {
      height: 88vh;
      right: 6vh;
      top: 6vh;
      width: calc(100vw - 12vh);
    }
  }
}
