.cookie-banner {
  background-color: $color-theme;
  color: $color-highlight;
  font: $typography-description;
  padding: 1vh;
  position: fixed;
  text-align: center;
  top: -5em;
  transition: all 0.5s;
  width: 100%;
  z-index: $index-cookie-policy;

  &.is-visible {
    top: 0;
  }

  a {
    color: $color-highlight;
    position: relative;
    text-decoration: none;

    &::after {
      border-bottom: 1px solid $color-highlight;
      bottom: 0;
      content: " ";
      display: inline-block;
      left: 0;
      position: absolute;
      transition: all 0.2s;
      width: 100%;
    }

    &:hover {
      &::after {
        width: 0;
      }
    }
  }

  button {
    background-color: $color-theme;
    border: 1px solid $color-highlight;
    color: $color-highlight;
    cursor: pointer;
    font: $typography-description;
    margin-left: 1em;
    padding: .3em 0.7em;
    transition: all 0.2s;

    &:hover {
      background-color: $color-highlight;
      color: $color-theme;
    }
  }
}
