@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
* {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  position: relative; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

button:active, button:focus {
  outline: none; }

body {
  background: #000;
  font-size: 16px;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }

.clear {
  clear: both;
  display: block; }

.swiper-slide .content {
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 250;
  /*
  @include only-smartphone {
    padding: 4vh;
  }

  @include only-tablet {
    padding: 4vh;
  }
  */ }
  .swiper-slide .content .logo {
    margin-top: 10vh; }
    @media screen and (max-width: 34em) {
      .swiper-slide .content .logo {
        margin-top: 6vh; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .swiper-slide .content .logo {
        margin-top: 10vh; } }
    .swiper-slide .content .logo img {
      height: 160px;
      width: 160px; }
      @media screen and (max-width: 34em) {
        .swiper-slide .content .logo img {
          height: 95px;
          width: 95px; } }
      @media (min-width: 34.01em) and (max-width: 64em) {
        .swiper-slide .content .logo img {
          height: 160px;
          width: 160px; } }
      @media (min-width: 64.01em) and (max-width: 86em) {
        .swiper-slide .content .logo img {
          height: 120px;
          width: 120px; } }
  .swiper-slide .content .description {
    padding: 5vh 10vh 0;
    text-align: left; }
    @media screen and (max-width: 34em) {
      .swiper-slide .content .description {
        padding-top: 2vh; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .swiper-slide .content .description {
        padding-top: 2vh; } }
    @media (min-width: 64.01em) and (max-width: 86em) {
      .swiper-slide .content .description {
        padding-top: 0; } }
    .swiper-slide .content .description h3 {
      color: #fff;
      font: 500 5em "Abril Fatface"; }
      @media screen and (max-width: 34em) {
        .swiper-slide .content .description h3 {
          font: 500 1.6em "Abril Fatface";
          text-align: center; } }
      @media (min-width: 34.01em) and (max-width: 64em) {
        .swiper-slide .content .description h3 {
          font: 500 4em "Abril Fatface";
          text-align: center; } }
    .swiper-slide .content .description p {
      color: #fff;
      font: normal 1.125em "PT Sans";
      line-height: 1.5em;
      padding-top: 3vh; }
      @media screen and (max-width: 34em) {
        .swiper-slide .content .description p {
          font: normal 0.8em "PT Sans";
          line-height: 1.3em;
          margin: 0;
          padding-top: 2vh; } }
      @media (min-width: 34.01em) and (max-width: 64em) {
        .swiper-slide .content .description p {
          font: normal 1.125em "PT Sans";
          line-height: 1.3em;
          margin: 0;
          padding-top: 5vh; } }
      @media (min-width: 64.01em) and (max-width: 86em) {
        .swiper-slide .content .description p {
          padding-top: 0; } }
    .swiper-slide .content .description a {
      color: #fff; }
    .swiper-slide .content .description .details {
      border: 0.063em solid #fff;
      box-sizing: border-box;
      color: #fff;
      display: block;
      float: left;
      font: normal 1.125em "PT Sans";
      margin-top: 5vh;
      overflow: hidden;
      padding: 0.313em 3.125em;
      position: relative;
      transition: 0.5s; }
      @media screen and (max-width: 34em) {
        .swiper-slide .content .description .details {
          padding: 0.313em 0;
          text-align: center;
          width: 100%; } }
      @media (min-width: 34.01em) and (max-width: 64em) {
        .swiper-slide .content .description .details {
          margin-top: 10vh;
          padding: 0.313em 0;
          text-align: center;
          width: 100%; } }
      @media (min-width: 64.01em) and (max-width: 86em) {
        .swiper-slide .content .description .details {
          padding-top: 0; } }
      .swiper-slide .content .description .details span {
        position: relative;
        z-index: 280; }
      .swiper-slide .content .description .details:hover {
        color: black; }
        .swiper-slide .content .description .details:hover::before {
          width: 120%; }
      .swiper-slide .content .description .details::before {
        background: #fff;
        bottom: -0.063em;
        content: " ";
        height: 120%;
        left: -10%;
        position: absolute;
        transform: skewX(30deg);
        transition: 0.5s;
        width: 0; }

.next-slide {
  bottom: 10vh;
  position: absolute;
  text-align: center;
  width: 100%; }
  @media screen and (max-width: 34em) {
    .next-slide {
      display: none; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .next-slide {
      display: none; } }
  .next-slide svg {
    height: 3.125em;
    transform: scale(1);
    transition: all 0.2s;
    width: 3.125em; }
    .next-slide svg line,
    .next-slide svg circle {
      stroke: #fff;
      stroke-width: 2px; }
    .next-slide svg circle {
      background-color: transparent;
      fill: none; }
    .next-slide svg:hover {
      transform: scale(1.2); }

h3 {
  color: #fff;
  font-family: 500 5em "Abril Fatface";
  font-size: 3.75em; }

.main-swiper.swiper-container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.main-swiper .swiper-wrapper {
  overflow: hidden; }

.main-swiper .swiper-slide {
  display: flex;
  font-size: 1.125em; }

.main-swiper.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10vh; }
  .main-swiper.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0.938em 0; }

.main-swiper .swiper-pagination-bullet {
  background: transparent;
  border: 0;
  height: auto;
  opacity: 1;
  position: relative;
  transition: all 0.5s;
  width: auto; }
  .main-swiper .swiper-pagination-bullet:hover {
    background-color: #fff; }
    .main-swiper .swiper-pagination-bullet:hover:hover .swiper-pagination-bullet-label {
      opacity: 1;
      right: 3.125em; }
  .main-swiper .swiper-pagination-bullet .swiper-pagination-bullet-label {
    color: #fff;
    font-family: "PT Sans";
    opacity: 0;
    position: absolute;
    right: 1.875em;
    text-transform: uppercase;
    top: -0.2em;
    transition: all 0.5s; }
    .main-swiper .swiper-pagination-bullet .swiper-pagination-bullet-label::after {
      border-bottom: 0.063em solid #fff;
      content: " ";
      display: block;
      position: absolute;
      right: -1.875em;
      top: 0.563em;
      width: 1.563em; }
  .main-swiper .swiper-pagination-bullet .swiper-pagination-bullet-inner {
    background-color: transparent;
    border: 0.063em solid #fff;
    border-radius: 50%;
    display: block;
    height: 0.938em;
    width: 0.938em; }

.main-swiper .swiper-pagination-bullet-active .swiper-pagination-bullet-inner {
  background-color: #fff; }

@media screen and (max-width: 34em) {
  .swiper-pagination {
    display: none; } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .swiper-pagination {
    display: none; } }

.menu {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 370; }
  .menu .content {
    background-color: #000;
    height: 100vh;
    position: absolute;
    right: -25em;
    transition: all 0.2s;
    width: 25em;
    z-index: 370; }
    @media screen and (max-width: 34em) {
      .menu .content {
        right: -100vw;
        transition: all 0.5s;
        width: 100vw; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .menu .content {
        right: -100vw;
        transition: all 0.5s;
        width: 100vw; } }
  .menu .mCSB_scrollTools {
    left: 0;
    right: auto; }
    @media screen and (max-width: 34em) {
      .menu .mCSB_scrollTools {
        display: none;
        opacity: 0;
        visibility: hidden; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .menu .mCSB_scrollTools {
        display: none;
        opacity: 0;
        visibility: hidden; } }
    .menu .mCSB_scrollTools .mCSB_draggerContainer {
      padding-left: 0.625em; }
    .menu .mCSB_scrollTools .mCSB_dragger {
      background-color: #444;
      border-radius: 0.625em;
      padding: 0.188em 0; }
    .menu .mCSB_scrollTools .mCSB_dragger_bar {
      background-color: #000;
      width: 0.625em; }
  .menu .mCSB_inside > .mCSB_container {
    margin-left: 2.188em;
    margin-right: 0; }
    @media screen and (max-width: 34em) {
      .menu .mCSB_inside > .mCSB_container {
        margin-left: 0; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .menu .mCSB_inside > .mCSB_container {
        margin-left: 0; } }

.menu-open {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10vh;
  top: 10vh;
  width: 6.25em; }
  @media screen and (max-width: 34em) {
    .menu-open {
      right: 6vh;
      top: 6vh; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .menu-open {
      right: 6vh;
      top: 6vh; } }
  .menu-open svg {
    height: 2.125em;
    width: 6.25em; }
    .menu-open svg line {
      stroke: #fff;
      stroke-width: 0.2em;
      transition: all 0.3s; }
      .menu-open svg line.line1 {
        stroke-dasharray: 70 100; }
      .menu-open svg line.line2 {
        stroke-dasharray: 50 100; }
      .menu-open svg line.line3 {
        stroke-dasharray: 30 100; }
  .menu-open:hover svg line.line1 {
    stroke-dasharray: 30 100; }
  .menu-open:hover svg line.line3 {
    stroke-dasharray: 70 100; }

.menu-overlay {
  cursor: e-resize;
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 1s;
  width: 100vw;
  z-index: 360; }

.options {
  display: block;
  height: 80vh;
  margin: 0;
  overflow: hidden;
  padding: 0; }
  @media screen and (max-width: 34em) {
    .options {
      height: 84vh; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .options {
      height: 84vh; } }
  .options li {
    display: block;
    height: 12vh;
    margin-top: 0.625em;
    overflow: hidden; }
    @media screen and (max-width: 34em) {
      .options li {
        margin-top: 0; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .options li {
        margin-top: 0; } }
  .options .option {
    background-position: center;
    background-size: 100% auto; }
    .options .option.option-home {
      background-image: url("../../app/assets/images/menu/menu-home.jpg"); }
    .options .option.option-ispirazioni {
      background-image: url("../../app/assets/images/menu/menu-ispirazioni.jpg"); }
    .options .option.option-linee {
      background-image: url("../../app/assets/images/menu/menu-linee.jpg"); }
    .options .option.option-nascita {
      background-image: url("../../app/assets/images/menu/menu-nascita.jpg"); }
    .options .option.option-unico {
      background-image: url("../../app/assets/images/menu/menu-unico.jpg"); }
    .options .option.option-territorio {
      background-image: url("../../app/assets/images/menu/menu-territorio.jpg"); }
    .options .option.option-certificazioni {
      background-image: url("../../app/assets/images/menu/menu-certificazioni.jpg"); }
    .options .option.option-contatti {
      background-image: url("../../app/assets/images/menu/menu-contatti.jpg"); }
  .options a {
    color: #fff;
    display: block;
    font-family: "PT Sans";
    font-size: 1.25em;
    font-weight: bold;
    height: 12vh;
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .options a::before {
      background-color: #000;
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      top: -12vh;
      transition: all 0.3s;
      width: 100%; }
    .options a:hover::before {
      top: 0; }
    .options a span {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

.language-selector {
  height: 20vh;
  position: relative; }
  @media screen and (max-width: 34em) {
    .language-selector {
      height: 16vh; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .language-selector {
      height: 16vh; } }
  .language-selector .menu-close {
    background-color: #000;
    border-width: 0;
    cursor: pointer;
    height: 1.875em;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.875em;
    top: 50%;
    width: 1.875em; }
    .language-selector .menu-close:hover {
      transform: rotate(180deg);
      transition: all 0.5s; }
    .language-selector .menu-close svg {
      height: 1.875em;
      width: 1.875em; }
      .language-selector .menu-close svg line {
        stroke: #fff;
        stroke-width: 0.125em; }
  .language-selector ul {
    display: block;
    left: 1.875em;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%; }
    .language-selector ul li {
      display: inline-block; }
  .language-selector a {
    border-bottom: 0.063em solid #555;
    color: #555;
    display: inline-block;
    font-family: "PT Sans";
    padding-bottom: 0.375em;
    text-align: center;
    transition: all 0.5s;
    width: 1.875em; }
    .language-selector a:hover, .language-selector a.is-active {
      border-color: #fff;
      color: #fff;
      text-decoration: none; }

.is-menu-open .menu .content {
  right: 0; }

@media (min-width: 64.01em) {
  .is-menu-open .wallpaper {
    transform: translateX(-12.5em); } }

.is-menu-open .menu .menu-overlay {
  display: block;
  opacity: 1; }

.modals-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s;
  z-index: -100; }
  .modals-overlay.is-visible {
    opacity: 1;
    z-index: 450; }

.modals .swiper-container-horizontal {
  height: 100%; }

.modal {
  background-color: #fff;
  border-radius: 0.938em;
  bottom: 10vh;
  display: block;
  height: 80vh;
  left: 10vh;
  opacity: 0;
  padding: 10vh;
  position: fixed;
  right: 10vh;
  top: 10vh;
  transform: scale(0.9);
  transition: all 0.5s;
  z-index: -100; }
  @media screen and (max-width: 34em) {
    .modal {
      bottom: 0;
      height: 100vh;
      left: 0;
      padding: 10vh 5vh 5vh;
      right: 0;
      top: 0; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .modal {
      bottom: 0;
      height: 100vh;
      left: 0;
      padding: 10vh 5vh 5vh;
      right: 0;
      top: 0; } }
  .modal.is-visible {
    opacity: 1;
    transform: scale(1);
    z-index: 460; }
  .modal .swiper-container {
    height: 100%; }
    .modal .swiper-container .swiper-slide {
      height: 100%;
      overflow-y: auto; }
  .modal .modal-close {
    position: absolute;
    right: 4.5vh;
    text-align: right;
    top: 4.5vh; }
    .modal .modal-close img {
      width: 2vh; }
      @media screen and (max-width: 34em) {
        .modal .modal-close img {
          width: 4vh; } }
      @media (min-width: 34.01em) and (max-width: 64em) {
        .modal .modal-close img {
          width: 4vh; } }
  .modal .modal-swiper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .modal .modal-slide {
    display: block;
    height: 100%;
    width: 100%; }
  .modal .swiper-pagination {
    padding: 2vh 0;
    width: calc(100vw - 40vh); }
  .modal .swiper-button-next {
    background-image: url("../../app/assets/images/arrow-right.svg");
    right: 3vh; }
    @media screen and (max-width: 34em) {
      .modal .swiper-button-next {
        display: none; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .modal .swiper-button-next {
        display: none; } }
  .modal .swiper-button-prev {
    background-image: url("../../app/assets/images/arrow-left.svg");
    left: 3vh; }
    @media screen and (max-width: 34em) {
      .modal .swiper-button-prev {
        display: none; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .modal .swiper-button-prev {
        display: none; } }
  .modal .swiper-button-disabled {
    opacity: 0; }
  .modal .swiper-pagination-bullet {
    background-color: transparent;
    border: 0.13em solid #000;
    border-radius: 50%;
    height: 1em;
    margin: 0 0.3em;
    opacity: 1;
    transition: all 0.5s;
    width: 1em; }
    .modal .swiper-pagination-bullet:hover, .modal .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #000;
      border-color: #000; }
  .modal .swiper-slide-youtube iframe {
    height: 90%;
    width: calc(100vw - 40vh); }
    @media screen and (max-width: 34em) {
      .modal .swiper-slide-youtube iframe {
        width: 90%; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .modal .swiper-slide-youtube iframe {
        width: 90%; } }
  .modal h2 {
    color: #000;
    font: 500 2em "Abril Fatface"; }
  .modal h3 {
    color: #000;
    font: 500 1.5em "Abril Fatface"; }
  .modal p,
  .modal th,
  .modal td {
    font: normal 1em "PT Sans"; }
  .modal .gallery {
    height: 100%;
    text-align: center; }
    .modal .gallery img {
      max-height: 90%;
      max-width: 90%;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

.slide-picture-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }
  .slide-picture-text::after {
    clear: both;
    content: " ";
    display: table; }
  .slide-picture-text img {
    display: block;
    float: left;
    margin-left: 5%;
    max-height: 100%;
    max-width: 35%; }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-picture-text img {
        display: inline;
        margin-left: 0;
        margin-right: 5%;
        width: 50%; } }
  .slide-picture-text p {
    float: right;
    font: normal 1em "PT Sans";
    margin-right: 5%;
    max-width: 50em;
    width: 50%; }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-picture-text p {
        float: none;
        margin-right: 0;
        width: 100%; } }

@media screen and (max-width: 34em) {
  .slide-text {
    font-size: 0.8em; } }

.wallpaper {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
  width: 100vw; }
  .wallpaper .wallpaper-video {
    height: 100%;
    overflow: hidden;
    position: relative; }
  .wallpaper video {
    background-size: cover;
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: 100; }
  .wallpaper .cover {
    background-position: center;
    background-size: auto;
    height: 100vh;
    width: 100vw; }
  .wallpaper .overlay {
    background: linear-gradient(to right, black 0%, black 33%, rgba(0, 0, 0, 0.4) 53%, rgba(0, 0, 0, 0.4) 100%);
    height: 100vh;
    left: -200vw;
    position: absolute;
    top: 0;
    width: 300vw;
    z-index: 190; }
    .wallpaper .overlay.overlay-dark {
      left: 0; }
    .wallpaper .overlay.overlay-gradient {
      left: -100vw; }

.swiper-slide-active .wallpaper video {
  z-index: 150; }

@media (min-width: 64.01em) {
  .slide-home .cover {
    background-image: url("../../app/assets/images/background/home-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-home .cover {
    background-image: url("../../app/assets/images/background/home-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-home .cover {
    background-image: url("../../app/assets/images/background/home-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-ispirazioni .cover {
    background-image: url("../../app/assets/images/background/ispirazioni-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-ispirazioni .cover {
    background-image: url("../../app/assets/images/background/ispirazioni-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-ispirazioni .cover {
    background-image: url("../../app/assets/images/background/ispirazioni-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-linee .cover {
    background-image: url("../../app/assets/images/background/linee-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-linee .cover {
    background-image: url("../../app/assets/images/background/linee-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-linee .cover {
    background-image: url("../../app/assets/images/background/linee-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-nascita .cover {
    background-image: url("../../app/assets/images/background/nascita-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-nascita .cover {
    background-image: url("../../app/assets/images/background/nascita-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-nascita .cover {
    background-image: url("../../app/assets/images/background/nascita-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-unico .cover {
    background-image: url("../../app/assets/images/background/unico-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-unico .cover {
    background-image: url("../../app/assets/images/background/unico-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-unico .cover {
    background-image: url("../../app/assets/images/background/unico-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-territorio .cover {
    background-image: url("../../app/assets/images/background/territorio-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-territorio .cover {
    background-image: url("../../app/assets/images/background/territorio-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-territorio .cover {
    background-image: url("../../app/assets/images/background/territorio-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-certificazioni .cover {
    background-image: url("../../app/assets/images/background/certificazioni-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-certificazioni .cover {
    background-image: url("../../app/assets/images/background/certificazioni-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-certificazioni .cover {
    background-image: url("../../app/assets/images/background/certificazioni-smartphone.jpg"); } }

@media (min-width: 64.01em) {
  .slide-contatti .cover {
    background-image: url("../../app/assets/images/background/contatti-desktop.jpg"); } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-contatti .cover {
    background-image: url("../../app/assets/images/background/contatti-tablet.jpg"); } }

@media screen and (max-width: 34em) {
  .slide-contatti .cover {
    background-image: url("../../app/assets/images/background/contatti-smartphone.jpg"); } }

.frame {
  height: 90vh;
  margin-left: 5vh;
  margin-top: 5vh;
  position: absolute;
  width: calc(100vw - 10vh);
  z-index: 230; }
  @media screen and (max-width: 34em) {
    .frame {
      height: 94vh;
      margin-left: 3vh;
      margin-top: 3vh;
      width: calc(100vw - 6vh); } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .frame {
      height: 94vh;
      margin-left: 3vh;
      margin-top: 3vh;
      width: calc(100vw - 6vh); } }
  .frame line {
    stroke: #fff;
    stroke-dasharray: 0% 100%;
    stroke-width: 0.125em; }

.loader {
  background-color: #000;
  height: 100vh;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: 550; }
  .loader svg {
    height: 200px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 200px; }

.cookie-banner {
  background-color: #000;
  color: #fff;
  font: normal 1.125em "PT Sans";
  padding: 1vh;
  position: fixed;
  text-align: center;
  top: -5em;
  transition: all 0.5s;
  width: 100%;
  z-index: 430; }
  .cookie-banner.is-visible {
    top: 0; }
  .cookie-banner a {
    color: #fff;
    position: relative;
    text-decoration: none; }
    .cookie-banner a::after {
      border-bottom: 1px solid #fff;
      bottom: 0;
      content: " ";
      display: inline-block;
      left: 0;
      position: absolute;
      transition: all 0.2s;
      width: 100%; }
    .cookie-banner a:hover::after {
      width: 0; }
  .cookie-banner button {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    font: normal 1.125em "PT Sans";
    margin-left: 1em;
    padding: .3em 0.7em;
    transition: all 0.2s; }
    .cookie-banner button:hover {
      background-color: #fff;
      color: #000; }

.slide-home h1 img {
  padding-top: 20vh;
  transition: all 1.5s;
  width: 250px; }

.slide-home h1.animate-logo img {
  padding-top: 10vh;
  width: 160px; }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-home h1.animate-logo img {
      padding-top: 10vh; } }

.slide-home h2 {
  color: #fff;
  font: 500 5em "Abril Fatface";
  padding: 10vh 5vw 0; }
  @media screen and (max-width: 34em) {
    .slide-home h2 {
      font: 500 1.6em "Abril Fatface"; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-home h2 {
      font: 500 4em "Abril Fatface"; } }

.slide-linee .particles {
  height: 50vh;
  position: absolute;
  right: 20vh;
  width: 40vw; }
  @media screen and (max-width: 34em) {
    .slide-linee .particles {
      height: 88vh;
      right: 6vh;
      top: 6vh;
      width: calc(100vw - 12vh); } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-linee .particles {
      height: 88vh;
      right: 6vh;
      top: 6vh;
      width: calc(100vw - 12vh); } }

.slide-nascita .nascita-draggable {
  height: 450px;
  left: 50%;
  position: absolute;
  top: 40vh;
  transform: translateX(-50%);
  width: 900px; }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-nascita .nascita-draggable {
      display: none; } }
  @media screen and (max-width: 34em) {
    .slide-nascita .nascita-draggable {
      display: none; } }
  .slide-nascita .nascita-draggable svg {
    height: 100%;
    width: 100%; }
    .slide-nascita .nascita-draggable svg circle {
      fill: none;
      stroke: #fff;
      stroke-dasharray: 0,4.0236;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      transform: scale(1); }
      .slide-nascita .nascita-draggable svg circle.target-reached {
        stroke-dashoffset: -200px;
        transform: scale(1.2) translate(-77px, -18px);
        transition: scale 1s; }
    .slide-nascita .nascita-draggable svg .path {
      opacity: 0.5;
      stroke: #fff;
      stroke-dasharray: 2px 2px;
      stroke-dashoffset: 15, 100%;
      stroke-width: 1px; }
    .slide-nascita .nascita-draggable svg .draggable-handler {
      opacity: 1; }
      .slide-nascita .nascita-draggable svg .draggable-handler.target-reached {
        opacity: 0;
        transition: all 1s; }

.slide-contatti .tab-selector {
  display: none; }
  @media screen and (max-width: 34em) {
    .slide-contatti .tab-selector {
      display: block; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-contatti .tab-selector {
      display: block;
      margin: 4vh 0; } }
  .slide-contatti .tab-selector span {
    border-bottom: 0.063em solid #555;
    color: #555;
    cursor: pointer;
    display: inline-block;
    font-family: "PT Sans";
    padding-bottom: 0.375em;
    transition: all 0.5s;
    width: 6em; }
    .slide-contatti .tab-selector span:hover, .slide-contatti .tab-selector span.is-active {
      border-color: #fff;
      color: #fff;
      text-decoration: none; }

@media screen and (max-width: 34em) {
  .slide-contatti .content .description {
    padding: 0; } }

@media screen and (max-width: 34em) {
  .slide-contatti .description {
    left: calc(-100vw + 6vh);
    padding: 2vh 0 0;
    position: absolute;
    transition: all 0.5s;
    width: calc(100vw - 12vh); }
    .slide-contatti .description h3 {
      display: none; }
    .slide-contatti .description .fa {
      margin: 0.3em; }
    .slide-contatti .description.is-selected {
      left: 6vh; } }

@media (min-width: 34.01em) and (max-width: 64em) {
  .slide-contatti .description {
    left: calc(-100vw + 6vh);
    padding: 2vh 0 0;
    position: absolute;
    transition: all 0.5s;
    width: calc(100vw - 12vh); }
    .slide-contatti .description .fa {
      margin-right: 0.3em; }
    .slide-contatti .description.is-selected {
      left: 6vh; } }

.slide-contatti form {
  float: right;
  margin-right: 20vh;
  min-height: 40vh;
  padding-top: 10vh;
  position: relative;
  width: 40vw; }
  @media screen and (max-width: 34em) {
    .slide-contatti form {
      float: none;
      left: calc(100vw + 6vh);
      padding-top: 2vh;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh); }
      .slide-contatti form.is-selected {
        left: 6vh; } }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .slide-contatti form {
      float: none;
      left: calc(100vw + 6vh);
      padding-top: 2vh;
      position: absolute;
      transition: all 0.5s;
      width: calc(100vw - 12vh); }
      .slide-contatti form.is-selected {
        left: 6vh; } }
  .slide-contatti form .loader-container {
    background: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5 s;
    width: 100%;
    z-index: 10; }
    .slide-contatti form .loader-container img {
      height: 200px;
      position: relative;
      top: 50%;
      transform: translateY(-80%);
      width: 200px; }
  .slide-contatti form .message-container {
    color: #fff;
    font: normal 1.125em "PT Sans";
    z-index: -999; }
    @media screen and (max-width: 34em) {
      .slide-contatti form .message-container {
        font: normal 1em "PT Sans"; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-contatti form .message-container {
        font: normal 1em "PT Sans"; } }
    .slide-contatti form .message-container::before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 0.5em;
      text-rendering: auto; }
    .slide-contatti form .message-container.message-success::before {
      color: #4c4;
      content: "\f00c"; }
    .slide-contatti form .message-container.message-success > span {
      color: #4c4; }
    .slide-contatti form .message-container.message-fail::before {
      color: #f00;
      content: "\f12a"; }
    .slide-contatti form .message-container.message-fail > span {
      color: #f00; }
    .slide-contatti form .message-container > a {
      color: #fff;
      display: none;
      font-size: 1em;
      padding-bottom: 5px;
      position: relative;
      text-decoration: none;
      transition: all 0.2s; }
      @media screen and (max-width: 34em) {
        .slide-contatti form .message-container > a {
          font-size: 0.8em; } }
      .slide-contatti form .message-container > a::after {
        border-bottom: 1px solid #fff;
        bottom: 0;
        content: " ";
        display: inline-block;
        left: 0;
        position: absolute;
        transition: all 0.2s;
        width: 0; }
      .slide-contatti form .message-container > a:hover {
        color: #fff; }
        .slide-contatti form .message-container > a:hover::after {
          width: 100%; }
  .slide-contatti form.is-pending .form-container {
    filter: opacity(50%);
    transition: all 0.5;
    z-index: 0; }
  .slide-contatti form.is-pending .loader-container {
    display: block;
    opacity: 1; }
  .slide-contatti form.is-submitted .form-container {
    display: none;
    transition: all 0.5; }
  .slide-contatti form.is-submitted .loader-container {
    opacity: 0;
    z-index: -9999; }
  .slide-contatti form.is-submitted .message-container {
    position: relative;
    z-index: 999; }
  .slide-contatti form input[type="text"],
  .slide-contatti form input[type="email"],
  .slide-contatti form textarea {
    background-color: #3c3c3c;
    border: 0.125em solid #666;
    border-radius: 5px;
    color: #fff;
    display: block;
    font: normal 1.125em "PT Sans";
    padding: 0.625em 25px;
    transition: border 0.5s; }
    .slide-contatti form input[type="text"]::-webkit-input-placeholder,
    .slide-contatti form input[type="email"]::-webkit-input-placeholder,
    .slide-contatti form textarea::-webkit-input-placeholder {
      color: #a0a0a0; }
    .slide-contatti form input[type="text"]:-moz-placeholder,
    .slide-contatti form input[type="email"]:-moz-placeholder,
    .slide-contatti form textarea:-moz-placeholder {
      color: #a0a0a0; }
    .slide-contatti form input[type="text"]::-moz-placeholder,
    .slide-contatti form input[type="email"]::-moz-placeholder,
    .slide-contatti form textarea::-moz-placeholder {
      color: #a0a0a0; }
    .slide-contatti form input[type="text"]:-ms-input-placeholder,
    .slide-contatti form input[type="email"]:-ms-input-placeholder,
    .slide-contatti form textarea:-ms-input-placeholder {
      color: #a0a0a0; }
    @media screen and (max-width: 34em) {
      .slide-contatti form input[type="text"],
      .slide-contatti form input[type="email"],
      .slide-contatti form textarea {
        font: normal 1em "PT Sans";
        padding: 0.3em 0.5em; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-contatti form input[type="text"],
      .slide-contatti form input[type="email"],
      .slide-contatti form textarea {
        font: normal 1.125em "PT Sans"; } }
    .slide-contatti form input[type="text"]:hover, .slide-contatti form input[type="text"]:focus, .slide-contatti form input[type="text"]:active,
    .slide-contatti form input[type="email"]:hover,
    .slide-contatti form input[type="email"]:focus,
    .slide-contatti form input[type="email"]:active,
    .slide-contatti form textarea:hover,
    .slide-contatti form textarea:focus,
    .slide-contatti form textarea:active {
      border-color: #fff; }
    .slide-contatti form input[type="text"]:invalid,
    .slide-contatti form input[type="email"]:invalid,
    .slide-contatti form textarea:invalid {
      box-shadow: none; }
  .slide-contatti form input[type="text"],
  .slide-contatti form input[type="email"] {
    float: left;
    margin: 0 1% 0.938em;
    width: 48%; }
    @media screen and (max-width: 34em) {
      .slide-contatti form input[type="text"],
      .slide-contatti form input[type="email"] {
        margin-bottom: 2%; } }
  .slide-contatti form textarea {
    margin: 0 1% 0.938em;
    width: 98%; }
    @media screen and (max-width: 34em) {
      .slide-contatti form textarea {
        margin-bottom: 2%; } }
  .slide-contatti form .checkbox-container {
    color: #fff;
    font: normal 1.125em "PT Sans";
    margin: 0 1%;
    text-align: left; }
    @media screen and (max-width: 34em) {
      .slide-contatti form .checkbox-container {
        font: normal 1em "PT Sans";
        text-align: center; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-contatti form .checkbox-container {
        font: normal 1.125em "PT Sans";
        text-align: center; } }
  .slide-contatti form .checkbox:not(:checked), .slide-contatti form .checkbox:checked {
    left: -9999px;
    position: absolute; }
    .slide-contatti form .checkbox:not(:checked) + label, .slide-contatti form .checkbox:checked + label {
      cursor: pointer;
      padding-left: 25px;
      position: relative;
      transition: color 0.5s; }
      .slide-contatti form .checkbox:not(:checked) + label:hover, .slide-contatti form .checkbox:checked + label:hover {
        color: #fff; }
      .slide-contatti form .checkbox:not(:checked) + label::before, .slide-contatti form .checkbox:checked + label::before {
        background: #3c3c3c;
        border: 1px solid #666;
        border-radius: 3px;
        content: "";
        height: 17px;
        left: 0;
        position: absolute;
        top: 0.25em;
        width: 1em; }
      .slide-contatti form .checkbox:not(:checked) + label::after, .slide-contatti form .checkbox:checked + label::after {
        color: #fff;
        content: "✔";
        font-size: 18px;
        left: 4px;
        line-height: 0.8;
        position: absolute;
        top: 3px;
        transition: all 0.2s; }
  .slide-contatti form .checkbox:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0); }
  .slide-contatti form .checkbox:checked + label::after {
    opacity: 1;
    transform: scale(1); }
  .slide-contatti form button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    display: block;
    float: right;
    font: normal 1.125em "PT Sans";
    margin-right: 1%;
    overflow: hidden;
    padding: 0.313em 3.125em;
    position: relative;
    transition: 0.5s; }
    @media screen and (max-width: 34em) {
      .slide-contatti form button {
        margin-right: 0;
        margin-top: 3vh;
        padding: 0.313em 0;
        width: 100%; } }
    @media (min-width: 34.01em) and (max-width: 64em) {
      .slide-contatti form button {
        margin-right: 0;
        margin-top: 7vh;
        padding: 0.313em 0;
        width: 100%; } }
    .slide-contatti form button:hover {
      color: black; }
      .slide-contatti form button:hover::after {
        transform: skewX(30deg);
        width: 120%; }
    .slide-contatti form button::after {
      background: #fff;
      bottom: -1px;
      content: " ";
      height: 120%;
      left: -10%;
      position: absolute;
      transform: skewX(15deg);
      transition: 0.5s;
      width: 0;
      z-index: -1; }

.map {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%); }
  @media screen and (max-width: 34em) {
    .map {
      height: 100%; } }
  .map iframe {
    height: 55vh;
    width: 100%; }
    @media screen and (max-width: 34em) {
      .map iframe {
        height: 100%;
        width: 100%; } }

.legal-info {
  bottom: 10vh;
  color: #fff;
  position: absolute;
  text-align: center;
  width: 100%; }
  @media (min-width: 34.01em) and (max-width: 64em) {
    .legal-info {
      bottom: 5vh; } }
  @media screen and (max-width: 34em) {
    .legal-info {
      bottom: 5vh; } }
  .legal-info a {
    color: #a0a0a0;
    font-size: 1em;
    padding-bottom: 5px;
    position: relative;
    text-decoration: none;
    transition: all 0.2s; }
    @media screen and (max-width: 34em) {
      .legal-info a {
        font-size: 0.8em; } }
    .legal-info a::after {
      border-bottom: 1px solid #fff;
      bottom: 0;
      content: " ";
      display: inline-block;
      left: 0;
      position: absolute;
      transition: all 0.2s;
      width: 0; }
    .legal-info a:hover {
      color: #fff; }
      .legal-info a:hover::after {
        width: 100%; }

.slide-unico h3,
.slide-unico p,
.slide-unico .description a,
.slide-territorio h3,
.slide-territorio p,
.slide-territorio .description a,
.slide-nascita h3,
.slide-nascita p,
.slide-nascita .description a,
.slide-ispirazioni h3,
.slide-ispirazioni p,
.slide-ispirazioni .description a,
.slide-linee h3,
.slide-linee p,
.slide-linee .description a {
  opacity: 0; }

.slide-home h1 img {
  opacity: 0; }

.slide-home h2 span {
  opacity: 0; }

.slide-home .next-slide svg {
  opacity: 0; }

.slide-linee .particles {
  opacity: 0; }

.slide-nascita .nascita-draggable {
  opacity: 0; }
