.wallpaper {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
  width: 100vw;

  .wallpaper-video {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  video {
    background-size: cover;
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: $index-background-video-hidden;
  }

  .cover {
    background-position: center;
    background-size: auto;
    height: 100vh;
    width: 100vw;
  }

  .overlay {
    background: linear-gradient(to right,
      rgba($color-overlay, 1) 0%,
      rgba($color-overlay, 1) 33%,
      rgba($color-overlay, 0.4) 53%,
      rgba($color-overlay, 0.4) 100%
    );
    height: 100vh;
    left: -200vw;
    position: absolute;
    top: 0;
    width: 300vw;
    z-index: $index-background-overlay;

    &.overlay-dark {
      left: 0;
    }

    &.overlay-gradient {
      left: -100vw;
    }
  }
}

.swiper-slide-active .wallpaper video {
  z-index: $index-background-video;
}

@each $slide in $slides {
  .slide-#{$slide} {
    @include only-desktop {
      .cover {
        background-image: url("../../app/assets/images/background/#{$slide}-desktop.jpg");
      }
    }

    @include only-tablet {
      .cover {
        background-image: url("../../app/assets/images/background/#{$slide}-tablet.jpg");
      }
    }

    @include only-smartphone {
      .cover {
        background-image: url("../../app/assets/images/background/#{$slide}-smartphone.jpg");
      }
    }
  }
}
