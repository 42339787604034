.frame {
  height: 90vh;
  margin-left: 5vh;
  margin-top: 5vh;
  position: absolute;
  width: calc(100vw - 10vh);
  z-index: $index-frame;

  @include only-smartphone {
    height: 94vh;
    margin-left: 3vh;
    margin-top: 3vh;
    width: calc(100vw - 6vh);
  }

  @include only-tablet {
    height: 94vh;
    margin-left: 3vh;
    margin-top: 3vh;
    width: calc(100vw - 6vh);
  }

  line {
    stroke: $color-frame;
    stroke-dasharray: 0% 100%;
    stroke-width: 0.125em;
  }
}
