@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");

$typography-headings: 500 5em "Abril Fatface";
$typography-headings-smartphone: 500 1.6em "Abril Fatface";
$typography-headings-tablet: 500 4em "Abril Fatface";

$typography-description: normal 1.125em "PT Sans";
$typography-description-smartphone: normal 0.8em "PT Sans";
$typography-description-tablet: normal 1.125em "PT Sans";

$typography-links: normal 1.125em "PT Sans";

$typography-form: normal 1.125em "PT Sans";
$typography-form-smartphone: normal 1em "PT Sans";
$typography-form-tablet: normal 1.125em "PT Sans";

$typography-modal-headings: 500 2em "Abril Fatface";
$typography-modal-headings-minor: 500 1.5em "Abril Fatface";
$typography-modal-body: normal 1em "PT Sans";
