.slide-unico,
.slide-territorio,
.slide-nascita,
.slide-ispirazioni,
.slide-linee {
  h3,
  p,
  .description a {
    opacity: 0;
  }
}

.slide-home {
  h1 img {
    opacity: 0;
  }

  h2 span {
    opacity: 0;
  }

  .next-slide svg {
    opacity: 0;
  }
}

.slide-linee {
  .particles {
    opacity: 0;
  }
}

.slide-nascita {
  .nascita-draggable {
    opacity: 0;
  }
}
