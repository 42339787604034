@mixin placeholder-color ($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin only-smartphone {
  @media screen and (max-width: #{$media-smartphone}) {
    @content;
  }
}

@mixin only-tablet {
  @media (min-width: #{$media-smartphone + 0.01em}) and (max-width: #{$media-tablet}) {
    @content;
  }
}

@mixin only-notebook {
  @media (min-width: #{$media-tablet + 0.01em}) and (max-width: #{$media-notebook}) {
    @content;
  }
}

@mixin only-desktop {
  @media (min-width: #{$media-tablet + 0.01em}) {
    @content;
  }
}
