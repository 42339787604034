.slide-nascita {
  .nascita-draggable {
    height: 450px;
    left: 50%;
    position: absolute;
    top: 40vh;
    transform: translateX(-50%);
    width: 900px;

    @include only-tablet {
      display: none;
    }

    @include only-smartphone {
      display: none;
    }

    svg {
      height: 100%;
      width: 100%;

      circle {
        fill: none;
        stroke: $color-highlight;
        stroke-dasharray: 0,4.0236;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
        transform: scale(1);

        &.target-reached {
          stroke-dashoffset: -200px;
          transform: scale(1.2) translate(-77px,-18px);
          transition: scale 1s;
        }
      }

      .path {
        opacity: 0.5;
        stroke: $color-highlight;
        stroke-dasharray: 2px 2px;
        stroke-dashoffset: 15, 100%;
        stroke-width: 1px;
      }

      .draggable-handler {
        opacity: 1;

        &.target-reached {
          opacity: 0;
          transition: all 1s;
        }
      }
    }
  }
}
